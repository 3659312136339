<template>
  <div v-if="!hide">
    <NuxtLink v-if="imageOnly && ctaLink" :to="ctaLink" @click="sendStats">
      <CommonImageLoader
        class="shrink-0 rounded-xl border border-gray-200"
        :width="width"
        :height="height"
        :src="computedImage"
      />
    </NuxtLink>
    <template v-else>
      <div
        v-if="placement === 'full'"
        class="container text-center mb-6 md:mb-16"
      >
        <div
          v-if="icon && iconType != 'VoresStemmer'"
          class="flex items-center flex-col"
        >
          <img :src="icons[iconType]" class="h-15 w-auto mb-5" />
        </div>
        <h1
          class="text-center max-w-4xl mx-auto"
          :class="small ? 'headline-xs' : 'headline-s'"
        >
          <div>{{ $te(header1) ? $t(header1) : header1 }}</div>
          <div
            v-if="$te(header2) ? $t(header2) : header2"
            class="font-light"
            v-html="$te(header2) ? $t(header2) : header2"
          />
        </h1>
        <div class="flex gap-6 justify-center items-center">
          <NuxtLink v-if="ctaLink" :to="ctaLink" @click="sendStats">
            <CommonButton
              v-if="ctaLabel"
              :size="'large'"
              :bg="computedImage ? 'bg-white' : isMm ? 'bg-black' : 'bg-blue'"
              :text="computedImage ? 'text-black' : 'text-white'"
              :border="ctaBorder ? '' : 'border-none'"
              class="mt-6"
            >
              {{ $te(ctaLabel) ? $t(ctaLabel) : ctaLabel }}
            </CommonButton>
          </NuxtLink>
          <NuxtLink v-if="secondCtaLink" :to="secondCtaLink" @click="sendStats">
            <CommonButton
              v-if="secondCtaLabel"
              :size="'large'"
              :bg="computedImage ? 'bg-white' : isMm ? 'bg-black' : 'bg-blue'"
              :text="computedImage ? 'text-black' : 'text-white'"
              :border="secondCtaBorder ? '' : 'border-none'"
              class="mt-6"
            >
              {{ $te(secondCtaLabel) ? $t(secondCtaLabel) : secondCtaLabel }}
            </CommonButton>
          </NuxtLink>
        </div>

        <div
          v-if="disclaimer"
          class="body-xs mt-2 max-w-xl mx-auto disclaimer"
          :class="isMm ? 'text-black' : 'opacity-50'"
          v-html="$te(disclaimer) ? $t(disclaimer) : disclaimer"
        />
      </div>
      <template v-else>
        <div
          v-if="isMm && !computedImage && iconType !== 'VoresStemmer'"
          class="flex items-center flex-col"
        >
          <img
            class="w-45 h-45 -mb-12"
            src="/small_icon_MM_fuction_subscription.png"
          />
        </div>
        <div
          class="rounded-xl overflow-hidden"
          :class="[
            { 'mb-8': placement === 'wide' },
            isMm ? 'pt-6' : 'shadow',
            computedImage
              ? 'text-white bg-top bg-contain'
              : isMm && !paperColor && iconType !== 'VoresStemmer'
              ? 'bg-black bg-opacity-10'
              : isMm && !paperColor && iconType === 'VoresStemmer'
              ? 'bg-blue text-white'
              : 'bg-white',
          ]"
          :style="[
            {
              'background-image': computedImage
                ? 'url(' + computedImage + ')'
                : undefined,
            },
            { backgroundColor: paperColor },
          ]"
        >
          <div
            class="px-5 pb-8 text-center"
            :class="[
              computedImage && iconType === 'announcement'
                ? 'bg-gradient-to-t from-darkblue via-darkblue pt-15'
                : computedImage
                ? 'bg-gradient-to-t from-black via-black pt-15'
                : 'pt-8',
            ]"
          >
            <div
              v-if="!isMm && icon && iconType != 'VoresStemmer'"
              class="flex items-center flex-col"
            >
              <img :src="icons[iconType]" class="h-15 w-auto mb-2" alt="Icon" />
            </div>
            <div
              v-if="isMm && iconType === 'VoresStemmer'"
              class="flex items-center flex-col -mt-8"
            >
              <img
                src="~/assets/icons/icon_voresstemmer.svg"
                class="h-15 w-auto mb-2"
              />
            </div>
            <div
              class="ml-auto mr-auto text"
              :class="
                placement === 'side'
                  ? 'promo-title-small mb-6'
                  : 'promo-title mb-10'
              "
            >
              <div>{{ $te(header1) ? $t(header1) : header1 }}</div>
              <div
                class="font-light"
                v-html="$te(header2) ? $t(header2) : header2"
              />
            </div>
            <div class="flex justify-center items-center gap-6">
              <NuxtLink
                v-if="ctaLink && typeof ctaLink === 'object'"
                :to="ctaLink"
                @click="sendStats"
              >
                <CommonButton
                  v-if="ctaLabel"
                  :size="placement === 'side' ? 'medium' : 'large'"
                  :bg="
                    computedImage ? 'bg-white' : isMm ? 'bg-black' : 'bg-blue'
                  "
                  :text="computedImage ? 'text-black' : 'text-white'"
                  :border="ctaBorder ? '' : 'border-none'"
                  class="mt-6"
                >
                  {{ $te(ctaLabel) ? $t(ctaLabel) : ctaLabel }}
                </CommonButton>
              </NuxtLink>
              <a
                v-else-if="ctaLink && typeof ctaLink === 'string'"
                :href="ctaLink"
                @click="sendStats"
              >
                <CommonButton
                  v-if="ctaLabel"
                  :size="placement === 'side' ? 'medium' : 'large'"
                  :bg="
                    computedImage ? 'bg-white' : isMm ? 'bg-black' : 'bg-blue'
                  "
                  :text="computedImage ? 'text-black' : 'text-white'"
                  :border="ctaBorder ? '' : 'border-none'"
                  class="mt-6"
                >
                  {{ $te(ctaLabel) ? $t(ctaLabel) : ctaLabel }}
                </CommonButton>
              </a>
              <NuxtLink
                v-if="secondCtaLink"
                :to="secondCtaLink"
                @click="sendStats"
              >
                <CommonButton
                  v-if="secondCtaLabel"
                  :size="placement === 'side' ? 'medium' : 'large'"
                  :bg="
                    computedImage ? 'bg-white' : isMm ? 'bg-black' : 'bg-blue'
                  "
                  :text="computedImage ? 'text-black' : 'text-white'"
                  :border="secondCtaBorder ? '' : 'border-none'"
                  class="mt-6"
                >
                  {{ $t(secondCtaLabel) }}
                </CommonButton>
              </NuxtLink>
            </div>

            <div
              v-if="disclaimer"
              class="body-xs mt-2 disclaimer"
              :class="isMm ? 'text-black' : 'opacity-50'"
              v-html="$te(disclaimer) ? $t(disclaimer) : disclaimer"
            />
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script setup lang="ts">
import promoImageAnnouncementUrl from '@/assets/images/promoimage_announcement.png?url'
import promoIconQuiz from '@/assets/icons/promoicon_quiz.svg?url'
import promoIconQuizLight from '@/assets/icons/promoicon_quiz_light.svg?url'
import promoIconConversation from '@/assets/icons/promoicon_conversation.svg?url'
import promoIconConversationLight from '@/assets/icons/promoicon_conversation_light.svg?url'
import promoIconPodcast from '@/assets/icons/promoicon_podcast.svg?url'
import promoIconEmail from '@/assets/icons/promoicon_email.svg?url'
import promoIconVote from '@/assets/icons/promoicon_vote.svg?url'
import promoIconAnnouncement from '@/assets/icons/promoicon_announcement.svg?url'

const { isMm } = useBrand()

export type IconType =
  | 'quiz'
  | 'conversation'
  | 'podcast'
  | 'email'
  | 'vote'
  | 'announcement'
  | 'VoresStemmer'

const props = withDefaults(
  defineProps<{
    placement?: string
    header1?: string
    header2?: string
    cta?: string | object
    ctaLabel?: string
    ctaBorder?: boolean
    secondCta?: string | object
    secondCtaLabel?: string
    secondCtaBorder?: boolean
    disclaimer?: string
    iconType?: IconType
    image?: string
    icon?: boolean
    small?: boolean
    id?: string
    zone?: string
    hideIfLoggedIn?: boolean
    imageOnly?: boolean
    width?: number
    height?: number
    paperIds?: number[]
  }>(),
  {
    placement: '',
    header1: '',
    header2: '',
    disclaimer: '',
    icon: true,
    iconType: 'conversation',
    small: false,
    id: '',
    zone: '',
    hideIfLoggedIn: false,
    imageOnly: false,
    width: 300,
    height: 360,
    paperIds: () => [],
  }
)

const nuxtApp = useNuxtApp()
const config = useRuntimeConfig()
const indexStore = useIndexStore()
const dataLayer = useDataLayer()
const userStore = useUserStore()

const hasHydrated = useHasHydrated()

const hide = computed(() => {
  if (props.hideIfLoggedIn) {
    return hasHydrated.value && userStore.isLoggedIn
  }

  if (
    props.paperIds?.length > 0 &&
    indexStore.currentPaper?.RecordId &&
    !props.paperIds.includes(indexStore.currentPaper?.RecordId)
  ) {
    return true
  }

  return false
})

const computedImage = computed(() => {
  if (props.iconType === 'announcement') {
    return promoImageAnnouncementUrl
  } else if (props.image) {
    return props.image
  } else {
    return undefined
  }
})

const icons = computed(() => ({
  quiz: computedImage.value ? promoIconQuiz : promoIconQuizLight,
  conversation: computedImage.value
    ? promoIconConversation
    : promoIconConversationLight,
  podcast: promoIconPodcast,
  email: promoIconEmail,
  emailmm: '/small_icon_MM_fuction_email.png',
  vote: promoIconVote,
  announcement: promoIconAnnouncement,
}))

const paperColor = computed(() => {
  if (
    config.public.site.identifier !== 'mm_dk' ||
    !indexStore.currentPaper?.ColorRGB
  ) {
    return
  }

  return hexToRgba(indexStore.currentPaper.ColorRGB, 0.2)
})

const ctaLink = computed(() => getRoute(props.cta))

const secondCtaLink = computed(() => getRoute(props.secondCta))

const getRoute = (routeBinding: string | object | undefined) => {
  if (!routeBinding) {
    return
  }

  if (typeof routeBinding === 'object') {
    return routeBinding
  }

  const route =
    routeBinding.startsWith('https://') || routeBinding.startsWith('/')
      ? routeBinding
      : `/${nuxtApp.$t(routeBinding)}`

  return route
}

const sendStats = () => {
  dataLayer.promotionClick(props.id, props.header1, 'promo', props.placement)
}
</script>

<style lang="scss" scoped>
.text {
  max-width: 750px;
}
</style>

<style lang="scss">
.disclaimer a {
  @apply underline;

  &:hover {
    @apply no-underline;
  }
}
</style>
